@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --base-dark: #6d2e46;
    --base-mid: #a26769;
    --base-neutral: #d5b9b2;
    --base-light: #ece2d0;
    --base-accent: #cebebe;
    --base-green: #acaf9b;
    --base-green-dark: #6a6b5f;
    --base-brown: #593637;
    --base-black: #221d23;
  }

  @font-face {
    font-family: "parklane";
    src: url("./fonts/parklane.ttf");
    format: ("truetype");
  }

  @font-face {
    font-family: "lgc";
    src: url("./fonts/lgc.ttf");
    format: ("truetype");
  }

  @font-face {
    font-family: "abc";
    src: url("./fonts/abc.otf");
    format: ("opentype");
  }

  body {
    @apply scrollbar-hide m-0 bg-baseBlack font-abc text-baseLight;
  }
}

html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flipX {
  transform: scaleX(-1);
}
